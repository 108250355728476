









































































































import { Component, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import { LogItem } from './types'
import moment from 'moment'

@Component({ name: 'LogManage' })
export default class LogManage extends VueBase {
  private page = 1
  private pageSize = 20
  private total = 0
  private tableData: Array<LogItem> = []
  private selectIdSet: Array<number> = []
  private selectAllPage = false

  created() {
    this.getApiList()
    this.getIpList()
    this.getTableData()
  }

  private selectIdChange(val: any) {
    this.selectIdSet = val?.map((item: any) => item.log_id)
  }

  private selectAllChange() {
    if (this.selectAllPage) {
      this.selectIdSet = this.tableData.map((item: LogItem) => {
        return item.log_id
      })
    } else {
      this.selectIdSet = []
    }
  }

  private currentChange(val: number) {
    this.page = val
    this.getTableData()
  }

  private searchForm: any = {
    api: '',
    ip: '',
    endTime: '',
    startTime: ''
  }
  private time: any = null
  private separator: string = ''
  @Watch('time', {deep: true})
	onTimeChanged(newVal: any){
		console.log('---', newVal, newVal?.length < 1)
		if (!newVal || newVal?.length < 1) {
			this.separator = ''
			this.searchForm.endTime = ''
			this.searchForm.startTime = ''
			return
		}
		this.separator = '至'
		this.searchForm.startTime = moment(new Date(newVal[0])).format('YYYY-MM-DD HH:mm:ss')
		this.searchForm.endTime = moment(new Date(newVal[1])).format('YYYY-MM-DD HH:mm:ss')
		// this.searchForm.startTime = newVal[0]
		// this.searchForm.endTime = newVal[1]
	}
	@Watch('searchForm', {deep: true})
	onFormChanged(newVal: any){
    this.page = 1
		this.getTableData()
	}
  private async getTableData() {
    const params = {
      page: this.page,
      pageSize: this.pageSize,
      ...this.searchForm
    }
    this.loadingStart()
    const { status, msg, data, total } = await this.services.setting.logList(
      params
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.selectIdSet = []
    this.tableData = data
    this.total = total
  }

  private ipOptions: any = []
  private apiOptions: any = []

  async getApiList() {
    const { status, msg, data } = await this.services.setting.apiList()
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.apiOptions = data || []
  }
  async getIpList() {
    const { status, msg, data } = await this.services.setting.ipList()
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.ipOptions = data || []
  }

  private deleteDialogShow() {
    if (this.selectIdSet.length <= 0) {
      this.$message.warning('请选择日志')
      return
    }
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      cancelButtonClass: "cancelButtonClass",
      confirmButtonClass: "delete-btn",
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          确定删除日志?
        </div>
        <div class="tip">
          日志删除后不可恢复，请谨慎操作
        </div>
      `,
    }).then(() => {
      this.logDelete()
    })
  }
  private async logDelete() {
    const params = {
      ids: this.selectIdSet.join(','),
    }
    const { status, msg } = await this.services.setting.logDelete(params)
    if (status !== 201) {
      this.$message.error('日志删除失败，请再次尝试')
      return
    }
    this.$message.success('日志删除成功')
    this.selectAllPage = false
    await this.getTableData()
  }
  private async logClear() {
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '清空',
      cancelButtonClass: "cancelButtonClass",
      confirmButtonClass: "delete-btn",
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          确定清空日志?
        </div>
        <div class="tip">
          日志清空后不可恢复，请谨慎操作
        </div>
      `,
    }).then(async () => {
      const { status, msg } = await this.services.setting.logDelete({id: ''})
      if (status !== 201) {
        this.$message.error('日志清空失败，请再次尝试')
        return
      }
      this.$message.success('日志清空成功')
      await this.getTableData()
    })
    
  }
  private async logExport() {
    if (this.selectIdSet.length <= 0) {
      this.$message({
        showClose: true,
        message: this.$t('log.choseLog') as string,
        type: 'error',
      })
      return
    }
    window.open(`/api/v2/logs/export?ids=${this.selectIdSet.join(',')}`)
  }
}
